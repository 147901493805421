import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { PropsWithChildren, ReactNode } from "react";
import { RAFAttributesContext } from "../Providers/RAFAttributeRelatedListProvider";
import {
  GetFieldsDisplayName,
  GetSelectedFieldDescription,
  GetSelectedFieldDescriptionStyle
} from "../helpers/AppHelper";
import {
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../helpers/utils";
import "./InputStyle.scss";
import { RAFDefaultFieldProps, RAFFieldProps } from "./RFFUtils";

//const RAFTextBox = ({ field, label, required }) => {
interface IProps {
  count?: number;
  iconCss?: string;
  unsetHeight?: boolean;
  showSecondLabel?: boolean;
  rightSection?: React.ReactFragment | ReactNode;
  labelClass?: string;
  labelSecondaryClass?: string;
  onClickLabelText?: () => void;
}

function RAFFieldLabel<T>({
  field,
  label,
  description,
  labelClassName,
  descriptionAsLabel,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<IProps & RAFFieldProps<T>>) {

  const onClickLabelText = () => {
    if (disabled !== true && props.onClickLabelText) {
      props.onClickLabelText();
    }
  };

  return (
    <RAFAttributesContext.Consumer>
      {({ queryAttributes, attributeRelatedList }) => {
        const selectedFieldDescription = isNotNullAndUndefined(description)
          ? description
          : GetSelectedFieldDescription(
            queryAttributes,
            field.toString(),
            attributeRelatedList
          );

        const showAsLabel = GetSelectedFieldDescriptionStyle(
          queryAttributes,
          field.toString(),
          attributeRelatedList
        );

        const showDescriptionAsLabel = isNotNullAndUndefined(descriptionAsLabel)
          ? descriptionAsLabel
          : showAsLabel;

        const showSecondLabel = isNotNullAndUndefined(props.showSecondLabel)
          ? props.showSecondLabel
          : false;

        const labelClass = `form-label${props.unsetHeight ? "" : " with-height"}${isNotNullAndUndefined(props.labelClass) ? ` ${props.labelClass}` : ""
          }${required ? " required" : ""}${(isNotNullAndUndefined(props.onClickLabelText) && disabled !== true) ? ' pointer' : ''}`;

        const labelSecondaryClass = `form-label form-secondary-label${isNotNullAndUndefined(props.labelSecondaryClass)
          ? ` ${props.labelSecondaryClass}`
          : ""
          }${props.unsetHeight ? "" : " with-height"}${showSecondLabel ? "" : " hidden"
          }`;

        return (
          <div
            className={`${isNotNullAndUndefined(labelClassName) && labelClassName !== ""
              ? labelClassName
              : "col-12"
              } d-flex form-label-container${props.unsetHeight ? "" : " form-label-container-height"
              }`}
          >
            {showDescriptionAsLabel === true &&
              !IsNullOrWhiteSpace(selectedFieldDescription) ? (
              <div className="row gy-1">
                {isNotNullAndUndefined(label) && (
                  <div className="col-12">
                    <label className={labelClass} onClick={onClickLabelText}>{label}</label>
                  </div>
                )}
                <div className="col-12">
                  <label className={labelSecondaryClass} onClick={onClickLabelText}>
                    {selectedFieldDescription}
                  </label>
                </div>
              </div>
            ) : showDescriptionAsLabel === false &&
              isNotNullAndUndefined(selectedFieldDescription) &&
              IsNotNullOrWhiteSpace(selectedFieldDescription) ? (
              <div className="d-flex align-items-baseline">
                <label className={labelClass} onClick={onClickLabelText}>
                  {GetFieldsDisplayName(
                    queryAttributes,
                    field.toString(),
                    label,
                    attributeRelatedList
                  )}{" "}
                </label>
                <div className="ms-2 d-flex">
                  <TooltipComponent
                    content={selectedFieldDescription}
                    position="TopCenter"
                    className="d-flex"
                  >
                    <span className="form-label-icon fa fa-info-circle"></span>
                  </TooltipComponent>
                </div>
              </div>
            ) : (
              <label className={labelClass} onClick={onClickLabelText}>
                {GetFieldsDisplayName(
                  queryAttributes,
                  field.toString(),
                  label,
                  attributeRelatedList
                )}{" "}
              </label>
            )}

            {isNotNullAndUndefined(props.count) && props.count > 0 && (
              <span className="ms-1 e-badge e-badge-primary e-badge-circle">
                {props.count}
              </span>
            )}
            {isNotNullAndUndefined(props.iconCss) && (
              <span className={`form-label-icon ${props.iconCss}`}></span>
            )}
            {isNotNullAndUndefined(props.rightSection) && props.rightSection}
          </div>
        );
      }}
    </RAFAttributesContext.Consumer>
  );
}

export default RAFFieldLabel;
