import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { composeValidators, RAFFieldError } from "./RAFForm";
import { isRequired, RAFDefaultFieldProps, RAFFieldProps } from "./RFFUtils";

//const RAFTextBox = ({ field, label, required }) => {

function RAFTextBox<T>({
  field,
  label,
  width,
  created,
  onChanged,
  fieldInnerText,
  children,
  maxlength,
  description,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFFieldProps<T>>) {
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className="row gy-2 g-0" id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
          ></RAFFieldLabel>
        )}
        <div
          className={
            isNotNullAndUndefined(props.inputFieldClassName)
              ? props.inputFieldClassName
              : "col-12"
          }
        >
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input, meta }) => (
              <div
                className={
                  isNotNullAndUndefined(props.inputFieldClassName)
                    ? props.inputFieldClassName
                    : null
                }
              >
                <div
                  className={
                    isNotNullAndUndefined(fieldInnerText)
                      ? "fieldInnerText d-flex"
                      : "d-flex"
                  }
                >
                  <TextBoxComponent
                    id={`ttx_${field.toString()}`}
                    value={input.value}
                    name={input.name}
                    change={(e) => {
                      if (e.isInteracted) {
                        input.onChange(e.value);
                        if (isNotNullAndUndefined(onChanged)) {
                          onChanged(e.value);
                        }
                      }
                    }}
                    type="text"
                    created={() =>
                      isNotNullAndUndefined(created)
                        ? created(input.name)
                        : null
                    }
                    width={width && width !== null ? width : "100%"}
                    showClearButton={showClearButton}
                    placeholder={props.placeholder}
                    disabled={disabled}
                    cssClass={
                      meta.error && meta.touched ? "inputFieldError" : null
                    }
                    htmlAttributes={
                      isNotNullAndUndefined(maxlength)
                        ? { maxlength: maxlength }
                        : null
                    }
                  />
                  {isNotNullAndUndefined(fieldInnerText) && (
                    <span className="fieldInnerLabel">{fieldInnerText}</span>
                  )}
                </div>

                {props.hideRequiredMessage !== true ? (
                  <RAFFieldError name={field.toString()} />
                ) : (
                  ""
                )}
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default RAFTextBox;
