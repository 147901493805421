import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { PropsWithChildren, useContext, useEffect } from "react";
import { FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { ConvertSystemName, IsNullOrWhiteSpace, isNotNullAndUndefined } from '../../RAFComponents/helpers/utils';
import RAFTextBox from "../Inputs/RAFTextBox";
import { RAFFieldProps, RAFFormContext } from "../Inputs/RFFUtils";
import { showWarningToast } from "../Utility/RAFToastComponent";
import "./InputStyle.scss";

interface IProps {
    allowAdd?: boolean;
    maxId?: number;
}

function RAFChoiceColorOption<T>(
    { field,
        maxId,
        allowAdd = false,
        // children 
    }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const onAddClick = (field: keyof T | string) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.push(field.toString(), { Id: maxId + 1, Name: undefined, DisplayName: undefined, ColorCode: "#ffffff", DisplayOrder: undefined });
        }
        setTimeout(() => {
            let elementToFocus = null;
            let choicelistOption = document.querySelector("div[class='" + field.toString() + "_divChoicelistOption']");
            elementToFocus = isNotNullAndUndefined(choicelistOption) ? choicelistOption.lastElementChild.querySelector("input[name$='DisplayName']") : null;
            //console.log(elementToFocus);
            if (elementToFocus !== null) {
                elementToFocus.focus();
            }
        }, 100);
    };

    useEffect(() => {
        /* const initTribute = () => {
             if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
                 rafFormContextValue.form.mutators.push(field.toString(), { Id: maxId + 1, Name: undefined, DisplayName: undefined, ColorCode: '#BFEDD2', DisplayOrder: undefined })
             }
         }*/
        //initTribute();
    });

    const onChange = (args, name) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.setValue(name + '[ColorCode]', args.currentValue.hex);
        }
    };

    const onChangeDisplayName = (value, name) => {
        if (isNotNullAndUndefined(rafFormContextValue) && isNotNullAndUndefined(rafFormContextValue.form) && isNotNullAndUndefined(rafFormContextValue.form.mutators)) {
            rafFormContextValue.form.mutators.setValue(name + '[Name]', ConvertSystemName(value));
        }
    };

    let paletteColors = {
        'custom': ['#BFEDD2', '#FBEEB8', '#F8CAC6', '#ECCAFA', '#C2E0F4', '#2DC26B', '#F1C40F', '#E03E2D',
            '#B96AD9', '#3598DB', '#169179', '#E67E23', '#BA372A', '#843FA1', '#236FA1', '#ECF0F1', '#CED4D9', '#95A5A6', '#7E8C8D', '#34495E']
    };

    const onCreated = (e, field1, fields, index) => {
        const input = document.querySelector('input[name="' + e + '"]');
        input.addEventListener('keydown', function (event) {

            //if (/ ^ [0-9] $ /.test(e.key) || e.key === '.' || e.key === '-' || e.key === '') { e.preventDefault(); return true; }
            if (event['key'] === "Enter" && !IsNullOrWhiteSpace(input['value'])) {
                onAddClick(field1);

                event.preventDefault();
                event.stopPropagation();
            }
            else if (input['value'] === '') {
                //fields.remove(index);
            }
            return false;
        });
    };

    return (
        <div className="choicelist-container">
            <div className="list row g-0 gy-3">
                <div className="col-12">

                    <FieldArray name={field.toString()}>
                        {({ fields }) =>
                            <div className={field.toString() + "_divChoicelistOption row g-0 gy-3"}>
                                {fields.map((name, index) => (
                                    <div className="col-12" key={name}>
                                        <div className="row g-3 choiceColor-option-item">
                                            <div className="col OptionField">
                                                <div className="w-100">
                                                    <RAFTextBox formGroupClassName="mb-0" field={`${name}.DisplayName`} showLabel={false} placeholder="option" onChanged={(value) => onChangeDisplayName(value, name)} required created={e => onCreated(e, field, fields, index)} />
                                                </div>
                                            </div>
                                            <div className="col-auto colorPicker">
                                                <ColorPickerComponent
                                                    value={isNotNullAndUndefined(fields.value[index]['ColorCode']) ?
                                                        fields.value[index]['ColorCode'] : null}
                                                    mode='Palette'
                                                    //noColor
                                                    //showButtons={false}
                                                    //modeSwitcher={false}
                                                    //columns={5}
                                                    change={(e) => { onChange(e, name); }}

                                                //presetColors={paletteColors}
                                                />
                                            </div>
                                            <div className="col-auto d-flex align-self-start">
                                                <ButtonComponent iconCss="fas fa-xmark"
                                                    cssClass="primary-custom-button"
                                                    type="button" onClick={() => { fields.length > 1 ? fields.remove(index) : showWarningToast("Atleast 1 Item Required"); }} title="Click to remove">
                                                </ButtonComponent>
                                            </div>
                                            <div className="hidden">
                                                <RAFTextBox field={`${name}.Name`} showLabel={false} placeholder="option" cssClass="no-border" created={e => onCreated(e, field, fields, index)} />
                                                <RAFTextBox field={`${name}.Id`} showLabel={false} placeholder="option" cssClass="no-border" created={e => onCreated(e, field, fields, index)} />
                                                <RAFTextBox field={`${name}.ColorCode`} showLabel={false} cssClass="no-border" created={e => onCreated(e, field, fields, index)} />
                                                <RAFTextBox field={`${name}.DisplayOrder`} showLabel={false} cssClass="no-border" created={e => onCreated(e, field, fields, index)} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        }
                    </FieldArray>
                </div>
                {allowAdd &&
                    <div className="col-12">
                        <button type="button" className="link-button" onClick={() => onAddClick(field)} id={`btn_addOption_choicelist`}> Add Option</button>
                    </div>
                }
            </div>
        </div>
    );
}

export default RAFChoiceColorOption;

