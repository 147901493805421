import { PropsWithChildren } from 'react';
import "./InputStyle.scss";
import { RAFChoiceOptionProps } from './RFFUtils';


function RAFChoiceOption(
    {
        label,
        value,
        category,
        children,
        //  IconCss 
    }: PropsWithChildren<RAFChoiceOptionProps>,
) {
    return (
        <div>
        </div>
    );
}


export default RAFChoiceOption;