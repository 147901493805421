import { InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "file-icon-vectors/dist/file-icon-vivid.min.css";
import moment from "moment";
import { PropsWithChildren, useEffect } from "react";
import RAFLoginUserContextProvider from "./RAFAuthentication/RAFLoginUserContextProvider";
import RAFRolePermissionsContextProvider from "./RAFAuthentication/RAFRolePermissionsContextProvider";
import { RAFAuthModule, loginRequest } from "./RAFAzure/AuthModule";
import RAFFullScreenContextProvider, {
  RAFFullScreenContext,
} from "./RAFComponents/Providers/RAFFullScreenContextProvider";
import RAFToastComponent from "./RAFComponents/Utility/RAFToastComponent";
import { Logout } from "./RAFComponents/helpers/AppHelper";
import { BrowserIsDevice } from "./RAFComponents/helpers/Constants";
import RequestInterceptor from "./RAFComponents/services/axios/RequestInterceptor";
import WorkspaceContextProvider from "./RAFModules/Common/Providers/WorkspaceContextProvider";
import NavigationMenuTreeView from "./components/shared/NavigationMenu/NavigationMenuTreeView/NavigationMenuTreeView";
import RAFFooterNavigationMenu from "./components/shared/NavigationMenu/RAFFooterNavigationMenu";
import RAFLeftNavigationMenu from "./components/shared/NavigationMenu/RAFLeftNavigationMenu";
import SkyLoadingPanel from "./components/shared/SkyLoadingPanel";
import { toggleTransparentBackdrop } from "./helpers/SkyUtils";
import skyloading2 from "./images/skytrust-loading.png";
import { AppRouter, AppRouterUnAuthenticated } from "./router";
import "../src/styles/CardStyle.scss";
import "../src/styles/ContainerStyle.scss";
import "../src/styles/DialogStyle.scss";
import "../src/styles/GeneralStyleSheet.scss";
import "../src/styles/main.scss";
import "../src/styles/mainBEMStyle.scss";
import "../src/styles/SFMainBEMStyle.scss";
import "../src/styles/SFStyle.scss";
import "../src/styles/SFmainStyle.scss";
import "../src/styles/tabStyle.scss";
import "./App.css";
import "./styles/FontStyle.scss";
import "./styles/button_style.scss";
import "./styles/constant_content_style.scss";
import "./styles/constant_surface_style.scss";
import "./styles/font_family.scss";
import "./styles/general_bem_style.scss";
import "./styles/sky_button_style.scss";
import { isNotNullAndUndefined } from "./RAFComponents/helpers/utils";

// export const LoginUserContext = React.createContext({ userInfo: null });
// export const RolePermissionsContext = React.createContext({
//   permissionValue: null,
//   hasPermission: null,
//   hasPermissions: null,
// });

moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "seconds",
    ss: "%ss",
    m: "a minute",
    mm: "%dm",
    h: "an hour",
    hh: "%dh",
    d: "a day",
    dd: "%dd",
    M: "a month",
    MM: "%dM",
    y: "a year",
    yy: "%dY",
  },
});

//export { ClearGridState as ClearGridState, GetGridState as GetGridState, hideProgress as hideProgress, SetGridState as SetGridState, showProgress as showProgress } from "./RAFComponents/helpers/AppHelper";
//export const LoginUserContext = React.createContext({ userInfo: null });
//export { LoginUserContext } from './RAFAuthentication/RAFLoginUserContextProvider';
//export const RolePermissionsContext = React.createContext({ permissionValue: null, hasPermission: null, hasPermissions: null });
//export { RolePermissionsContext } from './RAFAuthentication/RAFRolePermissionsContextProvider';
//export { ClearGridState as ClearGridState, GetGridState, hideProgress, SetGridState, showProgress } from "./RAFComponents/helpers/AppHelper";

interface IProps {
  //extends RouteComponentProps {
  pca: RAFAuthModule;
}

function App({ pca, ...props }: PropsWithChildren<IProps>) {
  useEffect(() => {
    if (BrowserIsDevice) {
      document.body.classList.add("e-bigger");
    }
    //window.addEventListener("scroll", handleScroll);
  });

  async function checkScrollable() {
    const iframe = document.querySelector("iframe");
    if (isNotNullAndUndefined(iframe) && iframe.style.visibility === "hidden") {
      iframe.classList.add("iframe-hidden");
    } else if (isNotNullAndUndefined(iframe)) {
      iframe.classList.remove("iframe-hidden");
    }
  }
  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  const IsBasePage = (): boolean => {
    let retVal: boolean = false;
    const windowLocationPathname = window.location.pathname.toLowerCase();
    switch (windowLocationPathname) {
      case "/login":
      case "/login/":
      case "/logout":
      case "/logout/":
      case "/activate":
      case "/activate/":
      case "/authsuccess":
      case "/authsuccess/":
        // case "/":
        retVal = true;
        break;
      default:
        break;
    }
    return retVal;
  };

  const leftNavigationMenuContent = () => {
    return <NavigationMenuTreeView />;
  };

  const leftNavigationMenuContent_copy = () => {
    return <RAFLeftNavigationMenu />;
  };

  const footerMenuContent = () => {
    return (
      <>
        <RAFFooterNavigationMenu />
      </>
    );
  };

  const Loading = () => {
    return <SkyLoadingPanel loadingText="Authentication in progress..." />;
  };

  const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {
    useEffect(() => {
      if (error) {
        if (
          error.errorCode === "redirect_uri_mismatch" ||
          error.errorCode === "authority_mismatch"
        ) {
          // Clear storage and log out on specific error codes
          localStorage.clear();
          sessionStorage.clear();
          Logout();
        }
      }
    }, [error]);

    if (!error) return null;

    return (
      <div>
        {error.errorCode === "redirect_uri_mismatch" ||
          error.errorCode === "authority_mismatch" ? (
          <div className="d-flex flex-column align-items-center">
            <img src={skyloading2} width="100px" alt="Loading" />
            <span className="loadingText text-center">
              Session Expired.
              <br />
              We are trying to log you in again.
            </span>
          </div>
        ) : (
          <div>An Error Occurred: {error.errorCode}</div>
        )}
      </div>
    );
  };

  const authRequest = { ...loginRequest };

  return (
    <MsalProvider instance={pca}>
      {/* <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      > */}
      <div id="sidebar-content" className="h-100 sidebar-content">
        <div className="p-0 container-fluid">
          <RAFFullScreenContextProvider>
            <RAFFullScreenContext.Consumer>
              {({ mode }) => {
                const isBasePage: boolean = IsBasePage();
                return (
                  <div
                    className={
                      mode === "default" ? "h-100" : "h-100 fullScreen"
                    }
                  >
                    <AuthenticatedTemplate>
                      <RequestInterceptor>
                        <RAFLoginUserContextProvider
                        // redirectStartPage={''}
                        >
                          <RAFRolePermissionsContextProvider>
                            <WorkspaceContextProvider>
                              <div className="row mainpage-container h-100 g-0 flex-nowrap">
                                {!isBasePage && (
                                  <div className="col-auto h-100 navigationMenu-col">
                                    {leftNavigationMenuContent()}
                                  </div>
                                )}
                                <main
                                  className="col page-wrapper h-100 overflow-hidden"
                                  id="mainTabDiv"
                                >
                                  {" "}
                                  {/* overflow-hidden added for fix the wi-5885 */}
                                  <div
                                    className="section__container position-relative"
                                    style={{ zIndex: "200" }}
                                  >
                                    <div
                                      className="transparentBackdrop"
                                      onClick={toggleTransparentBackdrop}
                                    ></div>
                                    <div className="section__div">
                                      <AppRouter />
                                    </div>
                                    {BrowserIsDevice && footerMenuContent()}
                                  </div>
                                </main>
                              </div>
                            </WorkspaceContextProvider>
                          </RAFRolePermissionsContextProvider>
                        </RAFLoginUserContextProvider>
                      </RequestInterceptor>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      {/* <LoginPage /> */}
                      <AppRouterUnAuthenticated />
                    </UnauthenticatedTemplate>
                  </div>
                );
              }}
            </RAFFullScreenContext.Consumer>
          </RAFFullScreenContextProvider>
        </div>
        <RAFToastComponent />
      </div>
      {/* </MsalAuthenticationTemplate> */}
    </MsalProvider>
  );
}

export default App;
